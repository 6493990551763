* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: 'Fira Code';
  font-size: large;
  line-height: 30px;
  user-select: none;
  height: 100vh;
  background: black;

  &.move {
    cursor: move;
  }
}

button {
  font-family: 'Fira Code';
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 21px;
}

#root {
  width: 100%;
  height: 100%;
}

header, .author {
  a {
    color: #00ff00;
    text-decoration: none;
    font-variation-settings: 'wght' 600;
    transition: all .3s;

    &:hover {
      font-variation-settings: 'wght' 900;
    }
  }
}